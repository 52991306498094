body{
    margin-top: 0px;
}

.modal-dialog{
    max-width: 800px;
    text-align: center;
    padding: 20px;
    margin-top: 60px;
}

.event-card:hover{

    background-color: #0004ff67;
}


.history-wrapper {
    margin-top: 100px;
    position: relative;
    padding: 50px 0 50px;
}
.history-wrapper:after {
    content: "";
    width: 3px;
    height: 100%;
    background: #ededed;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
}
.history-wrapper .title-wrap {
    opacity: 0.2;
    padding: 100px 0 0 45px;
}
.history-wrapper .timeline-box {
    position: relative;
}
.one-of-two {
    width: 50%;
    float: left;
}
.history-wrapper .timeline-box:not(:last-child) {
    margin-bottom: 140px;
}
.timeline-box:nth-child(2n) {
    padding: 0 0 0 140px;
}
.history-wrapper .timeline-box:nth-child(2n) {
    margin-bottom: 140px;
}
.history-wrapper .timeline-box:nth-child(2n + 1) {
    margin-top: -24%;
    text-align: right;
}
.timeline-box:nth-child(2n + 1) {
    padding: 0 140px 0 0;
}
.year {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    background: #fff;
    top: 30%;
    color: #3b3b3b;
    transform: translateY(-50%);
    border: 1px solid #dbdbdb;
    line-height: 100px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
}
.timeline-box:nth-child(2n) .year {
    left: 25px;
}
.timeline-box:nth-child(2n + 1) .year {
    right: 32px;
}
.year:before {
    content: "";
    width: 15px;
    height: 15px;
    border-left: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    background: #ffffff;
    position: absolute;
    top: 50%;
    margin-top: -7.5px;
}
.timeline-box:nth-child(2n) .year:before {
    left: -8px;
    transform: rotate(45deg);
}
.timeline-box:nth-child(2n + 1) .year:before {
    right: -8px;
    transform: rotate(-135deg);
}
.timeline-box:after {
    content: "";
    width: 3px;
    height: 0;
    background: #0004ff;
    position: absolute;
    top: 30%;
    transform: translateY(-50%);
    z-index: 1;
    transition: all 0.3s ease-in-out;
}
.timeline-box:nth-child(2n):after {
    left: -1.5px;
}
.timeline-box:nth-child(2n + 1):after {
    right: -1.5px;
}
.timeline-box:hover:after {
    height: 97px;
}
.timeline-box:before {
    content: "";
    width: 21px;
    height: 21px;
    border-radius: 50%;
    border: 5px solid #ededed;
    position: absolute;
    background: #fff;
    z-index: 2;
    top: 30%;
    transform: translateY(-50%);
    transition: all 0.3s ease-in-out;
}
.timeline-box:nth-child(2n):before {
    left: -10.5px;
}
.timeline-box:nth-child(2n + 1):before {
    right: -10.5px;
}
.timeline-box:hover:before {
    border: 5px solid #4400ff;
}
@media screen and (max-width: 1199px) {
    .year {
        width: 90px;
        height: 90px;
        line-height: 90px;
        font-size: 22px;
    }
}
@media screen and (max-width: 991px) {
    .history-wrapper {
        padding: 30px 0 30px;
    }
    .history-wrapper .title-wrap {
        padding: 0;
        margin-bottom: 30px;
    }
    .title-wrap.one-of-two {
        width: 100%;
    }
    .history-wrapper:after {
        left: 0;
    }
    .history-wrapper .timeline-box:nth-child(2n) {
        padding: 0 0 0 140px;
    }
    .history-wrapper .timeline-box:not(:last-child) {
        margin-bottom: 40px;
    }
    .timeline-box.one-of-two,
    .timeline-box .img img {
        width: 100%;
    }
    .history-wrapper .timeline-box:nth-child(2n + 1) {
        margin-top: 0;
        text-align: left;
    }
    .timeline-box:nth-child(2n + 1) .year {
        left: 32px;
    }
    .timeline-box:nth-child(2n + 1):before {
        left: -10.5px;
    }
    .timeline-box:nth-child(2n + 1) .year:before {
        left: -8px;
        transform: rotate(45deg);
    }
    .timeline-box:nth-child(2n + 1):after {
        left: -1.5px;
    }
    .history-wrapper .timeline-box:nth-child(2n + 1) {
        margin-top: 0;
        text-align: left;
    }
    .timeline-box:nth-child(2n + 1) {
        padding: 0 0 0 140px;
    }
}
@media screen and (max-width: 767px) {

    iframe{
        width: 80%;
    }

    .history-wrapper:after,
    .year,
    .timeline-box:before {
        display: none;
    }
    .history-wrapper {
        padding: 0;
    }
    .history-wrapper .timeline-box:nth-child(2n),
    .history-wrapper .timeline-box:nth-child(2n + 1) {
        padding: 0 15px;
    }
    .history-wrapper .timeline-box:not(:last-child) {
        margin-bottom: 30px;
    }
    .timeline-box .content {
        text-align: center;
    }
    .timeline-box:nth-child(2n):after,
    .timeline-box:nth-child(2n + 1):after {
        display: none;
    }
}

.mb-1-6, .my-1-6 {
    margin-bottom: 1.6rem;
}