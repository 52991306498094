.login-background {
    position: relative;
    width: 100%;
    padding: 5rem;    
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  

  @media (min-width: 576px) {

    .login-background {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 75%, #000 100%), url("../../images/login_back.jpg");
      background-position: center ;
    }
    
    .login-card{
      min-height: 75vh;
    }


  }