.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 100%;
    height: auto;
    
}

.member {
    background-color: whitesmoke;
}
.member:hover {
    background-color: rgba(0, 68, 255, 0.308);
}

.img-thumbnail:hover {
    padding: .25rem;
    background-color: rgb(0, 28, 184);
    border: 1px solid #000000;
    border-radius: .25rem;
    max-width: 100%;
    height: auto;
    
}

.social-link {
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    border-radius: 50%;
    transition: all 0.3s;
    font-size: 0.9rem;
}